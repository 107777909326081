<template>
  <qriously :size="size" :value="downloadLink" />
</template>
<script>
import axios from "axios";
import { isProduction } from '~/utils/envHelper.js';
import { getLangCodeByLang } from "~/utils/locale";
export default {
  data() {
    return {
      link: 'https://support.weex.today/register'
    };
  },
  props: {
    size: {
      type: Number,
      default: 150
    },
    lang: {
      type: String,
      default: 'zh-CN'
    },
    shareUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    downloadLink() {
      if (this.shareUrl !== '') {
        return this.shareUrl;
      } else {
        let link = this.link;
        let languageType = getLangCodeByLang(this.lang);
        if(this.downloadJumpDl){
          link = this.downloadJumpDl('download');
        }

        return `${link}?languageType=${languageType}`;
      }
    }
  },
  methods: {
    // async getQrcodeDomain() {
    //   let domain = 'https://dswcnhr3lpzog.cloudfront.net/icp.txt';
    //   let res1 = await axios.get(domain).then(res => res.data);
    //   this.link = this.AES_CBC_DECRYPT(res1, 'MwxmPJJHmXasfr45', 'yRKXEEPaEbHAbCPy');
    //   this.link = 'https://' + this.link + '/' + this.lang + '/jumpdl';
    // }
  },
  mounted() {
    //this.getQrcodeDomain(); //暫時取消使用微信專用域名
    // this.link = this.downloadJumpDl('', 'download');
  }
};
</script>
